import { Field, Image, ImageField, Link, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { classNames } from 'assets/utils/helper';
import Icons from '../atoms/Icons';
import React from 'react';

type DescriptionItem = {
	id: string;
	fields: {
		Title: Field<string>;
		link: LinkField;
	};
};

type SocialLinkItem = {
	id: string;
	fields: {
		Link: {
			value: {
				href: string;
			};
		};
		svg: {
			value: string;
		};
	};
};

type LinkItem = {
	id: string;
	fields: {
		Title: Field<string>;
		items: Array<DescriptionItem>;
	};
};

type R2FooterProps = {
	fields: {
		Logo: ImageField;
		DescriptionLinks: Array<DescriptionItem>;
		FooterDescription: Field<string>;
		SocialLinks: Array<SocialLinkItem>;
		items: Array<LinkItem>;
	};
};

const R2Footer = (props: R2FooterProps): JSX.Element => {
	const { fields } = props;

	return (
		<div className="r2-footer">
			<div className="footer-inner">
				<div className="footer-container">
					<div className="footer-logo-container">
						<div className="osf-logo">
							<a href="#" className="logo-link">
								<Image alt="osf_logo" field={fields?.Logo || ''} />
							</a>
						</div>
						<div className="social-links">
							{fields?.SocialLinks?.map(({ id, fields }) => {
								return (
									<a
										key={id}
										href={fields?.Link?.value?.href || '#'}
										rel="noreferrer"
										target="_blank"
										className="social-link"
									>
										<span className={classNames('icon', `icon-${fields?.svg?.value}` || '')}>
											<Icons id={`${fields?.svg?.value}-round` || ''} />
										</span>
									</a>
								);
							})}
						</div>
						<div className="mission-text">
							<em>
								In the spirit of Christ and the example of Francis of Assisi, the Mission of OSF
								HealthCare is to serve persons with the greatest care and love in a community that
								celebrates the Gift of Life.
							</em>
						</div>
					</div>
					<div className="link-blocks-container">
						{fields?.items?.map(({ id, fields }) => {
							return (
								<div key={id} className="footer-link-block">
									<Text className="footer-link-title" tag="h5" field={fields?.Title || ''} />
									<ul className="footer-link-list">
										{fields?.items?.map(({ id, fields }) => (
											<li key={id}>
												<Link
													className="footer-link"
													aria-label="Link Learn More"
													rel="noreferrer"
													field={fields?.link}
													onClick={(e) => e?.stopPropagation()}
												/>
											</li>
										))}
									</ul>
								</div>
							);
						})}
					</div>
				</div>
				<div className="footer-legal">
					<Text className="copyright-text" field={fields?.FooterDescription || ''} />
					<div className="legal-links">
						{fields?.DescriptionLinks?.map(({ id, fields }) => (
							<Link
								key={id}
								className="legal-link"
								aria-label="Link Learn More"
								rel="noreferrer"
								field={fields?.link}
								onClick={(e) => e?.stopPropagation()}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default R2Footer;
