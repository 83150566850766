import { MouseEventHandler, useEffect, useState } from 'react';
import { decodeHTMLEntities } from 'assets/utils/helper';

type rightRailProps = {
	Children: Array<rightRailProps>;
	DisplayName: string;
	Id: number;
	IsFolder: string;
	IsParent: string;
	Name: string;
	Url: string;
	IsExternalLink: string;
	ExternalLinkTarget: string;
}

type RailIconProps = {
	onClick: MouseEventHandler<HTMLDivElement>;
	active: boolean;
	activeClassName: string;
};
type RailProps = {
	text: string | undefined;
	active: boolean;
	activeClassName?: string;
	classNames?: string;
	onClickBtn?: MouseEventHandler<HTMLDivElement>;
	link?: string | undefined;
	isFolderOnly?: boolean;
	target: string;
};

function RailIcon({ onClick, active, activeClassName }: RailIconProps) {
	return (
		<div className={`rail-icon ${active && activeClassName}`} onClick={onClick}>
			<svg
				className={`icon icon-chevron-right ${active && activeClassName}`}
				xmlns="http://www.w3.org/2000/svg"
				width="8.121"
				height="14.122"
				viewBox="0 0 8.121 14.122"
			>
				<g id="Icon_Arrow_Right" data-name="Icon Arrow Right" transform="translate(1.061 1.061)">
					<path
						id="Path_747"
						data-name="Path 747"
						d="M4918.513-354.946l6,6-6,6"
						transform="translate(-4918.513 354.946)"
						fill="none"
					/>
				</g>
			</svg>
		</div>
	);
}

function Rail({ text, active, classNames, onClickBtn, link, isFolderOnly, target }: RailProps) {
	const RailContent = () => (
		<div className={`rail ${classNames}`} onClick={(e) => (isFolderOnly && onClickBtn) && onClickBtn(e)}>
			<div className='rail-link'>{text !== undefined && decodeHTMLEntities(text)}</div>
			{onClickBtn && (
				<RailIcon
					onClick={(e: React.MouseEvent<HTMLDivElement>) => {
						e.stopPropagation();
						e.preventDefault();
						onClickBtn(e);
					}}
					active={active}
					activeClassName="active"
				/>
			)}
		</div>
	);

	return (
		<a
			href={link !== '#0' ? link : '#'}
			className="rail-wrapper"
			target={target}
		>
			<RailContent />
		</a>
	);
}

const RightRailNavigation = (props: any): JSX.Element => {
	if (!props || !props?.fields || !props?.fields?.items) {
		return <div></div>;
	}

	const menuItems: rightRailProps = typeof props?.fields?.items == 'string' ? JSON.parse(props?.fields?.items) : props.fields.items;
	const [activeId, setActive] = useState(-1);
	const [activeZero, setActiveZero] = useState(false);
	const [activeOne, setActiveOne] = useState(-1);
	const [activeTwo, setActiveTwo] = useState(-1);
	const [activeThree, setActiveThree] = useState(-1);
	const [currentPage, setCurrentPage] = useState('');

	useEffect(() => {
		if (typeof window !== 'undefined' && menuItems) {
			const currPage = window?.location?.toString().substring(window.location.origin.toString().length)?.toLowerCase();
			setCurrentPage(currPage);

			if (menuItems.Children?.length > 0) {
				menuItems.Children.forEach((childOne) => {
					if (currPage?.includes(childOne.Url?.toLowerCase())) {
						setActiveOne(childOne.Id);
					}
					childOne.Children?.forEach((childTwo) => {
						if (currPage?.includes(childTwo.Url?.toLowerCase())) {
							setActiveTwo(childTwo.Id);
						}

						childTwo.Children?.forEach((childThree) => {
							if (currPage?.includes(childThree.Url?.toLowerCase())) {
								setActiveThree(childThree.Id);
							}
						});
					});
				});
			}
		}


	}, []);

	const onClickBtn = (idx: number, level: number) => {
		switch (level) {
			case 0:
				{ !activeZero ? setActiveZero(true) : setActiveZero(false); setActiveOne(-1); setActiveTwo(-1); setActiveThree(-1) }
				break;
			case 1:
				{ activeOne == idx ? setActiveOne(-1) : setActiveOne(idx); setActiveTwo(-1); setActiveThree(-1) }
				break;
			case 2:
				{ activeTwo == idx ? setActiveTwo(-1) : setActiveTwo(idx); setActiveThree(-1); }
				break;
			case 3:
				{ activeThree == idx ? setActiveThree(-1) : setActiveThree(idx); }
				break;
		}
		setActive(activeId === idx ? -1 : idx);
	};

	//This function recursively finds the last visible item in the nested menu structure based on the active indices.
	const getLastVisibleItem = (items: rightRailProps[], activeIndices: number[]): rightRailProps | null => {
		let lastVisibleItem: rightRailProps | null = null;
		if (!items) return lastVisibleItem;
		for (const item of items) {
			if (activeIndices.includes(item.Id)) {
				lastVisibleItem = getLastVisibleItem(item.Children, activeIndices) || item;
			} else if (!lastVisibleItem || !item.Children || item.Children.length === 0) {
				lastVisibleItem = item;
			}
		}
		return lastVisibleItem;
	};

	const activeIndices = [activeOne, activeTwo, activeThree].filter(id => id !== -1);
	const lastVisibleItem = getLastVisibleItem(menuItems.Children, activeIndices);

	const isLastVisible = (item: rightRailProps) => lastVisibleItem && lastVisibleItem.Id === item.Id;

	return (
		<>
			{menuItems.Id && (
				<div className="right-rail-navigation osf-rail">
					{menuItems.Name !== null && menuItems.Id !== null &&
						<div key={-1} className={'outer-rail top-level ' + (activeZero ? 'active' : '')} id={menuItems.Id.toString()}>
							<Rail
								text={menuItems.DisplayName}
								active={activeZero}
								classNames='parent'
								activeClassName='active'
								onClickBtn={() => onClickBtn(-1, 0)}
								link={menuItems.Url}
								target={menuItems.IsExternalLink === 'true' && menuItems.ExternalLinkTarget.length > 0 ? menuItems.ExternalLinkTarget : '_self'}
							/>
						</div>
					}
					{menuItems.Children?.map((child) => {
						const isActive = activeId === child.Id;
						const completeLink = child.IsFolder === 'true' ? undefined : child.Url;

						return (
							<div key={child.Id}>
								{child.Name !== null && child.Id !== null && !(child.IsFolder === 'true' && child.Children.length === 0) &&
									<div className={(activeOne == child.Id ? 'active ' : '') + (activeZero ? `outer-rail sub-rail level-1 show` : `outer-rail sub-rail level-1`) + (currentPage == child.Url?.toLowerCase() ? ' current' : '') + (isLastVisible(child) ? ' last-active' : '')} id={child.Id.toString()}>
										<Rail
											text={child.DisplayName}
											active={activeOne == child.Id}
											classNames={'sub'}
											activeClassName='active'
											onClickBtn={child.IsParent === "true" && child.Children.length > 0 ? () => onClickBtn(child.Id, 1) : undefined}
											link={completeLink}
											isFolderOnly={child.IsFolder === 'true' && child.Children?.length > 0}
											target={child.IsExternalLink === 'true' && child.ExternalLinkTarget.length > 0 ? child.ExternalLinkTarget : '_self'}
										/>
										{child.IsParent && !(child.IsFolder === 'true' && child.Children.length === 0) &&
											<div className={`sub-rail level-2 ${activeOne === child.Id ? 'show' : ''}`} id='2'>
												{child.Children.map((cnl) => {
													const completeLink = cnl.IsFolder === 'true' ? undefined : cnl.Url;

													return (
														<div key={cnl.Id}>
															{cnl.Name !== null && cnl.Id !== null && (
																<div className={`${activeTwo === cnl.Id ? 'active' : ''} outer-rail` + (currentPage == cnl.Url?.toLowerCase() ? ' current' : '') + (isLastVisible(cnl) ? ' last-active' : '')} id={cnl.Id.toString()}>
																	<Rail
																		key={cnl.Id}
																		text={cnl.DisplayName}
																		active={activeTwo == cnl.Id}
																		activeClassName="active"
																		classNames='child'
																		onClickBtn={cnl.IsParent === "true" && cnl.Children.length > 0 ? () => onClickBtn(cnl.Id, 2) : undefined}
																		link={completeLink}
																		isFolderOnly={cnl.IsFolder === 'true' && cnl.Children?.length > 0}
																		target={cnl.IsExternalLink === 'true' && cnl.ExternalLinkTarget.length > 0 ? cnl.ExternalLinkTarget : '_self'}
																	/>
																	{cnl.IsParent && cnl.Children && !(child.IsFolder === 'true' && child.Children.length === 0) &&
																		<div className={`sub-rail level-3 ${activeOne === child.Id && activeTwo === cnl.Id ? 'show' : ''}`} id='3'>
																			{cnl.Children.map((childLink) => {
																				const completeLink = childLink.IsFolder === 'true' ? undefined : childLink.Url;

																				return (
																					<div key={childLink.Id}>
																						{childLink.Name !== null && childLink.Id !== null &&
																							<div className={`${activeThree === childLink.Id ? 'active ' : ''}outer-rail` + (currentPage == childLink.Url?.toLowerCase() ? ' current' : '') + (isLastVisible(childLink) ? ' last-active' : '')} id={childLink.Id.toString()}>
																								<Rail
																									key={childLink.Id}
																									text={childLink.DisplayName}
																									active={activeThree == childLink.Id}
																									activeClassName="active"
																									classNames='child'
																									onClickBtn={childLink.IsParent === "true" && childLink.Children.length > 0 ? () => onClickBtn(childLink.Id, 3) : undefined}
																									link={completeLink}
																									isFolderOnly={childLink.IsFolder === 'true' && childLink.Children?.length > 0}
																									target={childLink.IsExternalLink === 'true' && childLink.ExternalLinkTarget.length > 0 ? childLink.ExternalLinkTarget : '_self'}
																								/>
																								{childLink.IsParent && childLink.Children && !(child.IsFolder === 'true' && child.Children.length === 0) &&
																									<div className={`sub-rail level-4 ${activeThree === childLink.Id ? 'show' : ''}`} id='4'>
																										{childLink.Children.map((cnl) => {
																											const completeLink = cnl.IsFolder === 'true' ? undefined : cnl.Url;

																											return (
																												<div key={cnl.Id}>
																													{cnl.Name !== null && cnl.Id !== null &&
																														<div className={`outer-rail` + (currentPage == cnl.Url?.toLowerCase() ? ' current' : '')} id={cnl.Id.toString()}>
																															<Rail
																																key={cnl.Id}
																																text={cnl.DisplayName}
																																active={isActive}
																																activeClassName="active"
																																classNames='child'
																																onClickBtn={cnl.IsParent === "true" && cnl.Children.length > 0 ? () => onClickBtn(cnl.Id, 4) : undefined}
																																link={completeLink}
																																isFolderOnly={cnl.IsFolder === 'true' && cnl.Children?.length > 0}
																																target={cnl.IsExternalLink === 'true' && cnl.ExternalLinkTarget.length > 0 ? cnl.ExternalLinkTarget : '_self'}
																															/>
																														</div>
																													}
																												</div>
																											)
																										})}
																									</div>
																								}
																							</div>
																						}
																					</div>
																				)
																			})}
																		</div>
																	}
																</div>
															)}
														</div>
													)
												})}
											</div>
										}
									</div>
								}
							</div>
						);
					})}
				</div>
			)}
		</>
	)
}

export default RightRailNavigation;
